import { uniq } from 'lodash';
import React, { useState } from 'react';
import uuidv4 from 'uuid/v4';

import { Button, Flex, Icon, Input } from 'components';

type Props = {
  initialValues?: Array<string>;
  initialPlaceholders?: Array<string>;
};

type Item = {
  title: string;
  id: string;
  placeholder?: string;
};

type RenderItemsProps = {
  placeholder: string;
  newItemPlaceholder: string;
  maxItems: number;
};

const useManageableTextList = ({
  initialValues,
  initialPlaceholders,
}: Props) => {
  const buildItem = (title: string, placeholder?: string) => ({
    title,
    id: uuidv4(),
    placeholder,
  });

  const [items, setItems] = useState<Array<Item>>([
    ...(initialValues
      ? initialValues.map(title => buildItem(title))
      : (initialPlaceholders || []).map(title => buildItem('', title))),
  ]);

  const [newItemTitle, setNewItemTitle] = useState('');

  const onItemCreation = () => {
    if (newItemTitle.length === 0) return;

    setItems([...items, buildItem(newItemTitle)]);
    setNewItemTitle('');
  };

  const onItemUpdate = (itemToUpdate: Item, input: string) => {
    if (input === '') {
      return onItemDeletion(itemToUpdate.id);
    }
    const index = items.findIndex(item => item.id === itemToUpdate.id);

    items[index].title = input;

    setItems([...items]);
  };

  const onItemDeletion = (id: string) => {
    setItems([...items.filter(item => item.id !== id)]);
  };

  const areItemsUnique = () => {
    const titles = items
      .map(item => item.title)
      .filter(title => title !== '')
      .concat(newItemTitle);

    return uniq(titles).length === titles.length;
  };

  const hasAtLeastOneItem = () => {
    return (
      items.map(item => item.title).filter(title => title !== '').length !== 0
    );
  };

  const renderItems = ({
    placeholder,
    newItemPlaceholder,
    maxItems,
  }: RenderItemsProps) => {
    return (
      <div className="text-list">
        {items.map(item => (
          <Flex className="text-list-item" key={item.id}>
            <Input
              className="text-list-input"
              value={item.title}
              placeholder={item.placeholder || placeholder}
              onChange={(input: string) => onItemUpdate(item, input)}
            />

            <Button
              isText
              hasIconOnly
              className="delete-button bg-nav-bg"
              onClick={() => onItemDeletion(item.id)}
            >
              <Icon size="small" name="close" />
            </Button>
          </Flex>
        ))}

        {items.length < maxItems && (
          <Flex className="add-item text-list-item mt-4">
            <Input
              className="text-list-input"
              value={newItemTitle}
              placeholder={newItemPlaceholder}
              onFocus={() => setNewItemTitle('')}
              onChange={(input: string) => setNewItemTitle(input)}
              onBlur={onItemCreation}
              onEnterKeyPress={onItemCreation}
            />
          </Flex>
        )}
      </div>
    );
  };

  return {
    items,
    areItemsUnique,
    hasAtLeastOneItem,
    renderItems,
  };
};

export default useManageableTextList;
