import React, { ReactNode } from 'react';

import type { UserObjectivePeriod } from 'models';

import can from 'helpers/can';
import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToReviewOrEvaluation } from 'helpers/paths';

import {
  Box,
  BoxSeparator,
  Column,
  Columns,
  Date,
  Field,
  Helper,
  Label,
  Link,
  Percentage,
  Placeholder,
  Text,
  Tooltip,
} from 'components';

type SummaryContentProps = {
  name: ReactNode;
  count: ReactNode;
  completion: ReactNode;
  lastUpdatedAt: ReactNode;
  relatedReviewsContent: ReactNode;
  showMissingCompletionTooltip?: boolean;
};
function SummaryContent({
  name,
  count,
  completion,
  lastUpdatedAt,
  relatedReviewsContent,
  showMissingCompletionTooltip,
}: SummaryContentProps) {
  return (
    <Box className="personal-objectives-summary">
      <Columns>
        <Column>
          <Field>
            <Label>{__('Period')}</Label>
            <Text className="value">{name}</Text>
          </Field>

          <Field>
            <Label>{__('Number of objectives')}</Label>
            <Text className="value">{count}</Text>
          </Field>

          <Field>
            <Label>{__('Average completion of objectives')}</Label>
            <Tooltip
              content={
                showMissingCompletionTooltip
                  ? __(
                      'The average completion cannot be computed because all the objectives are not published or because their weights are invalid.'
                    )
                  : null
              }
            >
              <Text className="value">{completion}</Text>
            </Tooltip>
          </Field>

          <Field>
            <Label>{__('Last update')}</Label>
            <Text className="value">{lastUpdatedAt}</Text>
          </Field>

          <div className="related-reviews">
            <BoxSeparator />

            <Label>{__('Related reviews')}</Label>

            {relatedReviewsContent}
          </div>
        </Column>
      </Columns>
    </Box>
  );
}

type Props = {
  userObjectivePeriod: UserObjectivePeriod;
};

function PersonalObjectivesSummary({ userObjectivePeriod }: Props) {
  const activeUser = useActiveUser();
  const { userReviews, objectiveCollection } = userObjectivePeriod;
  const { objectivesCount } = objectiveCollection;
  const showMissingCompletionTooltip =
    can({
      perform: 'manage_draft_objectives',
      on: objectiveCollection,
    }) &&
    objectiveCollection.overallCompletion === null &&
    objectivesCount !== 0;

  return (
    <SummaryContent
      name={userObjectivePeriod.name}
      count={objectivesCount}
      completion={<Percentage value={objectiveCollection.overallCompletion} />}
      showMissingCompletionTooltip={showMissingCompletionTooltip}
      lastUpdatedAt={
        <Date value={objectiveCollection.updatedAt} format="DD MMMM YYYY" />
      }
      relatedReviewsContent={
        !!userReviews && userReviews.length > 0 ? (
          userReviews.map(userReview => {
            return (
              <Columns className="review" key={userReview.id}>
                <Column size={4} style={{ padding: 0 }}>
                  <Text className="review-date">
                    <Date value={userReview.createdAt} format="MMM YYYY" />
                  </Text>
                </Column>

                <Column size={8} style={{ padding: 0 }}>
                  <Link
                    to={pathToReviewOrEvaluation(userReview, activeUser)}
                    disabled={!can({ perform: 'show_review', on: userReview })}
                    openInNewTab
                  >
                    <div style={{ display: 'inline-flex', maxWidth: 140 }}>
                      <Text overflowEllipsis>{userReview.name}</Text>
                    </div>
                  </Link>
                </Column>
              </Columns>
            );
          })
        ) : (
          <Helper>
            {__('These objectives haven’t been included in a review yet')}
          </Helper>
        )
      }
    />
  );
}

export function PersonalObjectivesSummaryPlaceholder() {
  return (
    <SummaryContent
      name={<Placeholder width="80%" />}
      count={<Placeholder width={20} />}
      completion={<Placeholder width={30} />}
      lastUpdatedAt={<Placeholder width="50%" />}
      relatedReviewsContent={<Placeholder width="80%" />}
    />
  );
}

export default PersonalObjectivesSummary;
