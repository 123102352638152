import React, { useState } from 'react';

import { SupportedLocale } from 'locales/index';

import { useAppDispatch, useCurrentOrganization } from 'helpers/hooks';
import { __, languageForLocale, localeSelectOptions } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { htmlSuccessNotice } from 'redux/actions';
import { post } from 'redux/actions/api';

import {
  Button,
  Checkbox,
  Field,
  FieldError,
  Input,
  Label,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Select,
  StrictlySanitizedHtml,
  Text,
} from 'components';

import AIIcon from 'scenes/components/AIIcon';
import AILogo from 'scenes/components/AILogo';
import useManageableTextList from 'scenes/skills/helpers/useManageableTextList';

type Props = {
  onClose: () => void;
  setAsyncActionId: (id: string) => void;
};

const MAX_LEVELS_COUNT = 10;

const CreationWithAiModal = ({ onClose, setAsyncActionId }: Props) => {
  const organization = useCurrentOrganization();
  const dispatch = useAppDispatch();

  const [matrixTitle, setMatrixTitle] = useState('');
  const [sector, setSector] = useState('');
  const [locale, setLocale] = useState<SupportedLocale>(organization.locale);
  const [softSkillsEnabled, setSoftSkillsEnabled] = useState(true);
  const [managementSkillsEnabled, setManagementSkillsEnabled] = useState(true);
  const { items, areItemsUnique, hasAtLeastOneItem, renderItems } =
    useManageableTextList({
      initialPlaceholders: [
        __('ex: Junior'),
        __('ex: Intermediate'),
        __('ex: Senior'),
      ],
    });

  const createMatrix = async () => {
    const response = await dispatch(
      post('/skills/matrices/create_with_ai', {
        matrix: {
          title: matrixTitle,
          levels: items
            .filter(item => item.title !== '')
            .map(item => item.title),
          locale,
          sector,
          softSkillsEnabled,
          managementSkillsEnabled,
        },
      })
    );

    const { id } = response.response.body.data;
    setAsyncActionId(id);

    dispatch(
      htmlSuccessNotice(
        __('Your skills matrix is being generated with Elevo AI.')
      )
    );

    onClose();
  };

  const isButtonDisabled =
    !areItemsUnique() ||
    !hasAtLeastOneItem() ||
    sector === '' ||
    matrixTitle === '';

  return (
    <ModalCard
      isActive
      onClose={onClose}
      isLarge
      refreshContentOnOpening
      className="skills-form-creation-modal"
    >
      <ModalCardHead>
        <ModalCardTitle>
          {__('Generate a skills matrix with')}
          <AILogo className="ml-2" />
        </ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody>
        <div className="mb-4">
          <StrictlySanitizedHtml
            html={__(
              'Fill in the information below and let the magic happen. <b>Once generated, you can edit it before publishing.</b>'
            )}
          />
        </div>

        <Field>
          <Label>{__('Skills matrix title')}</Label>
          <Input
            value={matrixTitle}
            placeholder={__('ex: Product Manager')}
            onChange={setMatrixTitle}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
        </Field>

        <Field>
          <Label>{__('Skills matrix levels')}</Label>

          <div className="mb-4">
            <Text preset="13s7">
              {__(
                'It can have one or more levels allowing employees to be assessed on each skill.'
              )}
            </Text>
          </div>

          {renderItems({
            placeholder: __('ex: Senior'),
            newItemPlaceholder: __('Add a level to the matrix...'),
            maxItems: MAX_LEVELS_COUNT,
          })}

          {!areItemsUnique() && (
            <FieldError>{__('Levels must be unique')}</FieldError>
          )}
        </Field>

        <Field>
          <Label>{__('Sector of your business')}</Label>
          <Input
            value={sector}
            placeholder={__('ex: Computer software')}
            onChange={setSector}
          />
        </Field>

        <Field>
          <Checkbox
            isChecked={softSkillsEnabled}
            onChange={setSoftSkillsEnabled}
            label={__('This matrix must contain soft skills')}
          />
        </Field>

        <Field>
          <Checkbox
            isChecked={managementSkillsEnabled}
            onChange={setManagementSkillsEnabled}
            label={__('This matrix must contain management skills')}
          />
        </Field>

        <Field>
          <Label>{__('Skills matrix language')}</Label>

          <Select
            value={{
              value: locale,
              label: languageForLocale(locale),
            }}
            options={localeSelectOptions(organization)}
            onChange={option => {
              invariant(
                option && !Array.isArray(option),
                'Locale cannot be empty'
              );

              setLocale(option.value);
            }}
            isClearable={false}
          />
        </Field>
      </ModalCardBody>

      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Cancel')}
        </Button>

        <Button
          color="primary"
          disabled={isButtonDisabled}
          onClick={createMatrix}
        >
          <AIIcon className="mr-1" />
          {__('Generate the skills matrix')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default CreationWithAiModal;
