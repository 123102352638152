import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ContentContainer } from 'components';

import ObjectivesSettings from './ObjectivesSettings';
import ReviewsSettings from './ReviewsSettings';
import ThreeSixtySettings from './ThreeSixtySettings';
import TrainingSettings from './TrainingSettings';

const SettingsRoutes = () => {
  return (
    <ContentContainer>
      <Routes>
        <Route path="/" element={<Navigate to="reviews" />} />
        <Route index path="reviews" element={<ReviewsSettings />} />
        <Route path="objectives" element={<ObjectivesSettings />} />
        <Route path="360" element={<ThreeSixtySettings />} />
        <Route path="training" element={<TrainingSettings />} />
      </Routes>
    </ContentContainer>
  );
};

export default SettingsRoutes;
