import React, {
  CSSProperties,
  type ReactNode,
  type Ref,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { type PopperChildrenProps, usePopper } from 'react-popper';

import classNames from 'helpers/classNames';
import createBodyPortal from 'helpers/react/createBodyPortal';

import { DesignSystem } from 'components';

const transitionDurationInMs = 200;

type TriggerProps = {
  innerRef: Ref<any>;
  children: ReactNode;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  className?: string;
  style?: CSSProperties;
};

type Placement = PopperChildrenProps['placement'];

function Trigger({
  innerRef,
  children,
  onMouseEnter,
  onMouseLeave,
  className,
  style,
}: TriggerProps) {
  return (
    <span
      ref={innerRef}
      className={className}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      style={style}
    >
      {children}
    </span>
  );
}

type ContentProps = {
  innerRef?: Ref<any>;
  style?: CSSProperties;
  children: ReactNode;
  isOpened?: boolean;
  className?: string;
};

export function Content({
  innerRef,
  style,
  children,
  isOpened,
  className,
  ...rest
}: ContentProps) {
  return (
    <DesignSystem version={2}>
      <div
        ref={innerRef}
        className={classNames('tooltip', className, {
          'is-opened': isOpened,
        })}
        style={style}
        {...rest}
      >
        {children}
      </div>
    </DesignSystem>
  );
}

Content.defaultProps = { isOpened: true };

type Props = {
  enabled?: boolean;
  children: ReactNode;
  content?: ReactNode | null | undefined;
  triggerClassName?: string;
  triggerStyle?: CSSProperties;
  testClassName?: string;
  placement?: Placement;
  onlyOnEllipsis?: boolean;
  maxWidth?: number;
  className?: string;
};

export default function Tooltip({
  enabled = true,
  children,
  content,
  triggerClassName,
  triggerStyle,
  testClassName,
  placement,
  onlyOnEllipsis,
  maxWidth = 360,
  className,
}: Props) {
  const timeoutHandler = useRef<number | null>(null);
  const [isMounted, setIsMounted] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const clearExistingTimeoutHandler = () => {
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
    }

    timeoutHandler.current = null;
  };

  const setTimeout = (fn: () => any, duration: number) => {
    timeoutHandler.current = window.setTimeout(fn, duration);
  };

  const hasEllipsis = () => {
    if (!referenceElement) return false;
    return (
      referenceElement.offsetWidth < referenceElement.scrollWidth ||
      referenceElement.scrollHeight > referenceElement.clientHeight
    );
  };

  const close = () => {
    clearExistingTimeoutHandler();
    setIsOpened(false);
    setTimeout(() => {
      setIsOpened(false);
      setIsMounted(false);
    }, transitionDurationInMs);
  };

  const open = () => {
    if (!enabled) return;
    if (onlyOnEllipsis && !hasEllipsis()) return;

    clearExistingTimeoutHandler();
    setIsMounted(true);
    setTimeout(() => {
      setIsOpened(true);
      setIsMounted(true);
    }, 0);
  };

  const [referenceElement, setReferenceElement] =
    useState<HTMLSpanElement | null>(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
      ],
    }
  );

  useLayoutEffect(() => {
    if (isOpened) {
      // We have to manually ask popper to update to correctly place
      // the popper element
      !!update && update();
    }
  }, [isOpened, update]);

  if (!content) {
    if (isOpened) {
      // Make sure that we close if content is null
      // This can happen if the onMouseLeave event has not been triggered
      close();
    }
    return <>{children}</>;
  }

  return (
    <>
      <Trigger
        className={classNames(triggerClassName, testClassName)}
        innerRef={setReferenceElement}
        onMouseLeave={close}
        onMouseEnter={open}
        style={triggerStyle}
      >
        {children}
      </Trigger>

      {isMounted &&
        createBodyPortal(
          <Content
            {...attributes.popper}
            innerRef={setPopperElement}
            isOpened={isOpened}
            style={{ maxWidth, ...styles.popper }}
            className={className}
          >
            {content}
          </Content>
        )}
    </>
  );
}

type TooltipOnEllipsisProps = {
  children: ReactNode;
  maxWidth?: number;
  plainText?: boolean;
  lineClamp?: number;
};

export const TooltipOnEllipsis = ({
  children,
  maxWidth = 360,
  plainText,
  lineClamp,
}: TooltipOnEllipsisProps) => {
  const turnIntoPlainText = useCallback((node: HTMLElement) => {
    if (node === null) return;
    node.querySelectorAll('.material-icons').forEach(icon => {
      // Display space instead of icon name
      icon.innerHTML = ' ';
    });
    node.innerHTML = node.innerText.replace(/\n/g, '<br />');
  }, []);

  const tooltipContent = plainText ? (
    <span ref={turnIntoPlainText}>{children}</span>
  ) : (
    children
  );

  return (
    <Tooltip
      maxWidth={maxWidth}
      triggerClassName={classNames({
        'is-text-overflow-ellipsis is-block': !lineClamp,
        [`text-wrap line-clamp-${lineClamp}`]: !!lineClamp,
      })}
      content={tooltipContent}
      onlyOnEllipsis
    >
      <span>{children}</span>
    </Tooltip>
  );
};

Tooltip.defaultProps = {
  placement: 'top' as Placement | undefined,
};
