import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { NavLink, useMatch } from 'react-router-dom';

import type { MaterialIconName } from 'components/Icon';
import type { TextPreset } from 'components/text/Text';

import { Icon, Link, Text, Tooltip } from 'components';

type NavigationItemPropsBase = {
  to?: string | undefined | null;
  onClick?: () => void;
  label: string;
  icon?: MaterialIconName;
  svgIcon?: ReactNode;
  testName?: string;
  rightContent?: React.ReactNode;
  labelPreset?: TextPreset;
  isDisabled?: boolean;
  disabledReason?: ReactNode;
};

type ClickableNavigationItemProps = NavigationItemPropsBase & {
  onClick: NonNullable<NavigationItemPropsBase['onClick']>;
  isActive?: boolean;
};

type LinkNavigationItemProps = NavigationItemPropsBase & {
  to: NonNullable<NavigationItemPropsBase['to']>;
  exact?: boolean;
};

export type NavigationItem =
  | ClickableNavigationItemProps
  | LinkNavigationItemProps;

const hasOnClick = (
  item: NavigationItem
): item is ClickableNavigationItemProps => {
  return !!item.onClick;
};

const hasLink = (item: NavigationItem): item is LinkNavigationItemProps => {
  return !!item.to;
};

type ItemIconProps = {
  icon?: MaterialIconName;
  svgIcon?: ReactNode;
};

const ItemIcon = ({ icon, svgIcon }: ItemIconProps) => {
  if (icon) {
    return (
      <>
        <Icon name={icon} />
        &nbsp;
      </>
    );
  }

  if (svgIcon) {
    return <>{svgIcon}&nbsp;</>;
  }

  return null;
};

type Props = {
  items: Array<NavigationItem>;
};

const ClickableNavigationItem = ({
  label,
  icon,
  svgIcon,
  rightContent,
  testName,
  onClick,
  isActive,
  labelPreset,
  isDisabled,
  disabledReason,
}: ClickableNavigationItemProps) => (
  <li
    className={classNames({
      'is-active': isActive,
      'is-disabled': isDisabled,
    })}
  >
    <Tooltip enabled={isDisabled} content={disabledReason}>
      <Link
        overriddenClassName={null}
        onClick={onClick}
        testClassName={classNames('test-link', testName)}
      >
        <ItemIcon icon={icon} svgIcon={svgIcon} />
        <Text preset={labelPreset}>{label}</Text>
        {rightContent}
      </Link>
    </Tooltip>
  </li>
);

const LinkNavigationItem = ({
  to,
  label,
  icon,
  svgIcon,
  rightContent,
  testName,
  labelPreset,
}: LinkNavigationItemProps) => {
  const isActive = useMatch(`${to.split('?')[0]}/*`);

  return (
    <li className={isActive ? 'is-active' : ''}>
      <NavLink
        to={to}
        className={classNames('test-link', testName, {
          active: isActive,
        })}
      >
        <ItemIcon icon={icon} svgIcon={svgIcon} />
        <Text preset={labelPreset}>{label}</Text>
        {rightContent}
      </NavLink>
    </li>
  );
};

export default function NavigationList({ items }: Props) {
  return (
    <ul>
      {items.map(item => {
        if (hasLink(item) && item.isDisabled) {
          item = {
            ...item,
            onClick: () => {},
            to: undefined,
          };
        }

        if (hasOnClick(item)) {
          return <ClickableNavigationItem {...item} key={item.label} />;
        } else if (hasLink(item)) {
          return <LinkNavigationItem {...item} key={item.label} />;
        } else {
          throw new Error(
            'Navigation item should have either `onClick` or `to`'
          );
        }
      })}
    </ul>
  );
}
