import React from 'react';

import { Column, Columns, Loading } from '../index';
import Overlay, { type Props as CommonProps } from './base';

type Props = CommonProps & {
  style?: React.CSSProperties;
  color?: string;
  delay?: number;
};

const LoadingOverlay = ({ children, style, color, delay }: Props) => (
  <Overlay style={style}>
    <Columns>
      <Column isVerticallyCentered isNarrow>
        <Loading color={color} delay={delay} containerStyle={{ padding: 15 }} />
      </Column>
      {/* `size={12}` serves no purpose here. It just fixes a display issue on Edge <16 */}
      {children && (
        <Column isVerticallyCentered size={12}>
          {children}
        </Column>
      )}
    </Columns>
  </Overlay>
);

export default LoadingOverlay;
