import React from 'react';
import { Route, Routes } from 'react-router-dom';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';

import EntityIndex from './EntityIndex';
import Integrations from './Integrations';
import NotificationsSettings from './NotificationsSettings';
import Rights from './Rights';
import SettingsRoutes from './Settings/SettingsRoutes';
import SyncDetails from './SyncDetails';
import UserList from './UserList';
import CycleRoutes from './reviewCycles/Routes';
import TemplateRoutes from './reviewTemplates/Routes';

const AdminRoutes = () => {
  const organization = useCurrentOrganization();

  return (
    <Routes>
      {can({ perform: 'show_review_campaign_menu', on: organization }) && (
        <Route path="cycles/*" element={<CycleRoutes />} />
      )}
      {can({ perform: 'show_review_template_menu', on: organization }) && (
        <Route path="templates/*" element={<TemplateRoutes />} />
      )}
      {can({ perform: 'show_user_directory_menu', on: organization }) && (
        <Route path="users" element={<UserList />} />
      )}
      {can({ perform: 'manage_admin_roles', on: organization }) && (
        <Route path="rights" element={<Rights />} />
      )}
      {can({ perform: 'manage_entities', on: organization }) && (
        <Route path="entities" element={<EntityIndex />} />
      )}
      {can({ perform: 'show_settings_menu', on: organization }) && (
        <Route path="settings/*" element={<SettingsRoutes />} />
      )}
      {can({ perform: 'show_user_sync_details', on: organization }) && (
        <Route path="sync/:id" element={<SyncDetails />} />
      )}
      {can({ perform: 'manage_integrations', on: organization }) && (
        <Route path="integrations" element={<Integrations />} />
      )}
      {can({ perform: 'manage_notifications_settings', on: organization }) && (
        <Route path="notifications" element={<NotificationsSettings />} />
      )}
    </Routes>
  );
};

export default AdminRoutes;
