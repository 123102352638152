import React from 'react';

import type { ObjectiveCollection } from 'models';

import can from 'helpers/can';
import { __, n__ } from 'helpers/i18n';

import { Box, Icon, Number, Text, Tooltip } from 'components';

type CollectionInfoProps = {
  objectiveCollection: ObjectiveCollection;
};

export default function CollectionInfo({
  objectiveCollection,
}: CollectionInfoProps) {
  const { overallCompletion, objectivesCount } = objectiveCollection;
  const showMissingCompletionTooltip =
    can({
      perform: 'manage_draft_objectives',
      on: objectiveCollection,
    }) &&
    overallCompletion === null &&
    objectivesCount !== 0;

  if (!objectivesCount) return null;

  return (
    <Box className="objectives-module-collection-info">
      <Icon
        className="objectives-module-collection-info-icon"
        name="track_changes"
      />
      <Text
        transformation="uppercase"
        weight="semibold"
        size={7}
        className="objectives-module-collection-info-content"
      >
        {!!overallCompletion
          ? n__(
              'Objective completion',
              'Average completion of %1 objectives',
              objectivesCount
            )
          : n__('%1 objective', '%1 objectives', objectivesCount)}
      </Text>
      <Tooltip
        content={
          showMissingCompletionTooltip
            ? __(
                'The average completion cannot be computed because all the objectives are not published or because their weights are invalid.'
              )
            : null
        }
      >
        <Text
          color="primary"
          weight="semibold"
          size={2}
          className="objectives-module-collection-info-percentage"
        >
          <Number value={overallCompletion} isPercentage />
        </Text>
      </Tooltip>
    </Box>
  );
}
