import React from 'react';

import type { UpdatableReviewCycleFields } from '../../index';
import type { ReviewCycle } from 'models';

import { useOrganizationSettings } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { Icon, Tooltip } from 'components';

import TagPicker from 'scenes/components/TagPicker';

import OptionWrapper from './OptionWrapper';

type Props = {
  reviewCycle: ReviewCycle;
  updateReviewCycle: (
    reviewCycle: Partial<UpdatableReviewCycleFields>
  ) => Promise<void>;
};

const TagsOption = ({ reviewCycle, updateReviewCycle }: Props) => {
  const { auditReportsEnabled } = useOrganizationSettings();
  const toolTipContent =
    __('Tags allow you to identify your campaigns.') +
    (reviewCycle.interactionType === 'one_on_one' && auditReportsEnabled
      ? ' ' + __('They can be used in creating your audit reports.')
      : '');

  return (
    <OptionWrapper
      title={
        <>
          {__('Tags')}
          <Tooltip content={toolTipContent}>
            <Icon style={{ marginLeft: 8 }} name="info" />
          </Tooltip>
        </>
      }
      subtitle={__('Define the tag(s) to associate with this campaign.')}
    >
      <TagPicker
        context={
          reviewCycle.interactionType === 'one_on_one'
            ? 'review_cycle'
            : 'three_sixty_review_cycle'
        }
        selectedTags={reviewCycle.tags || []}
        onTagsChange={tags =>
          updateReviewCycle({ tagLabels: tags.map(tag => tag.label) })
        }
      />
    </OptionWrapper>
  );
};

export default TagsOption;
