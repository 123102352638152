import React from 'react';

import { __ } from 'helpers/i18n';

import { Icon, Text, Tooltip } from 'components';

type Props = {
  label: string;
  isActive: boolean;
  onLinkClick: () => void;
  onRemoveClick: () => void;
  disabled: boolean;
  activeFilterLabel?: string;
};

const FilterText = ({
  label,
  isActive,
  onLinkClick,
  onRemoveClick,
  activeFilterLabel,
  disabled,
}: Props) => {
  return (
    <div className="flex items-center flex-shrink-0">
      <Tooltip
        triggerClassName="whitespace-nowrap truncate grow"
        content={__(
          'You have reached the maximum number of filters on this page'
        )}
        enabled={disabled}
      >
        <Text
          onLinkClick={onLinkClick}
          disabled={disabled}
          className={`whitespace-nowrap mr-1 ${isActive ? 'font-bold' : ''} ${
            disabled
              ? 'cursor-not-allowed hover:text-text-light text-text-light'
              : ''
          }`}
        >
          {label}
        </Text>
        {!!activeFilterLabel && (
          <Text className="truncate grow">{`: ${activeFilterLabel}`}</Text>
        )}
      </Tooltip>
      {isActive && (
        <Icon
          name="close"
          className="shrink-0 cursor-pointer"
          onClick={onRemoveClick}
        />
      )}
    </div>
  );
};

export default FilterText;
