import React from 'react';

import { __ } from 'helpers/i18n';

import {
  Button,
  DesignSystem,
  Icon,
  Testable,
  Text,
  WithSavingStatusRecorder,
} from 'components';

type Props = {
  onDelete?: () => Promise<void>;
  onMoveUp: () => Promise<void>;
  onMoveDown: () => Promise<void>;
  onDuplicate?: () => Promise<void>;
  canMoveUp: boolean;
  canMoveDown: boolean;
  canDuplicate: boolean;
};

const BlockActions = ({
  onDelete,
  onMoveUp,
  onMoveDown,
  onDuplicate,
  canMoveUp,
  canMoveDown,
  canDuplicate,
}: Props) => {
  return (
    <DesignSystem version={2}>
      <div className="block-actions">
        <Testable name="test-block-action-move-up">
          <WithSavingStatusRecorder
            onChange={onMoveUp}
            render={autoSavingOnchange => (
              <Button
                hasIconOnly
                onClick={autoSavingOnchange}
                disabled={!canMoveUp}
                isText
                size="small"
              >
                <Text color="soften">
                  <Icon size="small" name="arrow_upward" />
                </Text>
              </Button>
            )}
          />
        </Testable>
        <Testable name="test-block-action-move-down">
          <WithSavingStatusRecorder
            onChange={onMoveDown}
            render={autoSavingOnchange => (
              <Button
                hasIconOnly
                onClick={autoSavingOnchange}
                disabled={!canMoveDown}
                isText
                size="small"
              >
                <Text color="soften">
                  <Icon size="small" name="arrow_downward" />
                </Text>
              </Button>
            )}
          />
        </Testable>
        {canDuplicate && !!onDuplicate && (
          <Testable name="test-block-action-duplicate">
            <WithSavingStatusRecorder
              onChange={onDuplicate}
              render={autoSavingOnchange => (
                <Button
                  hasIconOnly
                  onClick={autoSavingOnchange}
                  isText
                  size="small"
                >
                  <Text color="soften">
                    <Icon
                      size="small"
                      style={{ fontSize: 14 }}
                      name="content_copy"
                    />
                  </Text>
                </Button>
              )}
            />
          </Testable>
        )}
        <Testable name="test-block-action-delete">
          {!!onDelete ? (
            <WithSavingStatusRecorder
              onChange={onDelete}
              render={autoSavingOnchange => (
                <Button
                  onClick={autoSavingOnchange}
                  isText
                  hasIconOnly
                  size="small"
                >
                  <Text color="soften">
                    <Icon size="small" style={{ width: 16 }} name="delete" />
                  </Text>
                </Button>
              )}
            />
          ) : (
            <Button
              isText
              hasIconOnly
              size="small"
              disabled={!onDelete}
              disabledExplanation={__(
                'Review blocks cannot be deleted since the campaign has started'
              )}
            >
              <Text color="soften">
                <Icon size="small" style={{ width: 16 }} name="delete" />
              </Text>
            </Button>
          )}
        </Testable>
      </div>
    </DesignSystem>
  );
};

export default BlockActions;
